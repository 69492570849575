import React from 'react';
import {Link} from 'react-router-dom';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import SwitchButton from './../../../components/IosSwitchButton'
import FormUser from './common/FormUser';
import UserApi from './../../../api/UserApi';
import AppOrgApi from './../../../api/AppOrgApi';


const setNode = (servicepermission) => {
    let nodes = [
        {
            value: 'setup',
            label: 'Setup',
            children: [
                { value: 'location', label: 'Location' },
                { value: 'user', label: 'User' },
                { value: 'openapi', label: 'Open API' },
            ],
        },
        {
            value: 'service',
            label: 'Service',
            children: [
                { 
                    value: 'member',
                    label: 'Member',
                    children: [
                        { 
                            value: 'member_list',
                            label: 'Member list'
                        }
                    ]
                },
                { 
                    value: 'parking',
                    label: 'Parking',
                    children: [
                        { 
                            value: 'parking_gate',
                            label: 'Gate'
                        },
                        { 
                            value: 'parking_entrance',
                            label: 'Entrance'
                        },
                        { 
                            value: 'parking_report',
                            label: 'Report'
                        }
                    ]
                },
                { 
                    value: 'visitor',
                    label: 'Visitor',
                    children: [
                        { 
                            value: 'visitor_gate',
                            label: 'Gate'
                        },
                        { 
                            value: 'visitor_entrance',
                            label: 'Entrance'
                        },
                        { 
                            value: 'visitor_report',
                            label: 'Report'
                        }
                    ]
                },
                { 
                    value: 'visitor_lite',
                    label: 'Visitor Lite',
                    children: [
                        { 
                            value: 'visitor_lite_gate',
                            label: 'Gate'
                        },
                        { 
                            value: 'visitor_lite_setting',
                            label: 'Setting'
                        },
                        { 
                            value: 'visitor_lite_entrance',
                            label: 'Entrance'
                        },
                        { 
                            value: 'visitor_lite_report',
                            label: 'Report'
                        }
                    ]
                },
                { 
                    value: 'visitor_m',
                    label: 'Visitor M',
                    children: [
                        { 
                            value: 'visitor_m_gate',
                            label: 'Gate'
                        },
                        { 
                            value: 'visitor_m_setting',
                            label: 'Setting'
                        },
                        { 
                            value: 'visitor_m_entrance',
                            label: 'Entrance'
                        },
                        { 
                            value: 'visitor_m_report',
                            label: 'Report'
                        }
                    ]
                },
                { 
                    value: 'visitor_m_plus',
                    label: 'Visitor M Plus',
                    children: [
                        { 
                            value: 'visitor_m_plus_gate',
                            label: 'Gate'
                        },
                        { 
                            value: 'visitor_m_plus_setting',
                            label: 'Setting'
                        },
                        { 
                            value: 'visitor_m_plus_entrance',
                            label: 'Entrance'
                        },
                        { 
                            value: 'visitor_m_plus_report',
                            label: 'Report'
                        }
                    ]
                },
                { 
                    value: 'visitor_kiosk',
                    label: 'Visitor Kiosk',
                    children: [
                        { 
                            value: 'visitor_kiosk_gate',
                            label: 'Gate'
                        },
                        { 
                            value: 'visitor_kiosk_setting',
                            label: 'Setting'
                        },
                        { 
                            value: 'visitor_kiosk_entrance',
                            label: 'Entrance'
                        },
                        { 
                            value: 'visitor_kiosk_report',
                            label: 'Report'
                        }
                    ]
                },
                { 
                    value: 'visitor_rent',
                    label: 'Visitor Rent',
                    children: [
                        { 
                            value: 'visitor_rent_gate',
                            label: 'Gate'
                        },
                        { 
                            value: 'visitor_rent_setting',
                            label: 'Setting'
                        },
                        { 
                            value: 'visitor_rent_entrance',
                            label: 'Entrance'
                        },
                        { 
                            value: 'visitor_rent_report',
                            label: 'Report'
                        }
                    ]
                },
                { 
                    value: 'check',
                    label: 'Check',
                    children: [
                        { 
                            value: 'check_report',
                            label: 'Report'
                        },
                        { 
                            value: 'check_setting',
                            label: 'Setting'
                        }
                    ]
                },
                { 
                    value: 'note',
                    label: 'Note',
                    children: [
                        { 
                            value: 'note_list',
                            label: 'Note'
                        },
                        { 
                            value: 'task_list',
                            label: 'Task'
                        }
                    ]
                }

            ],
        }
    ];

    return nodes
}

class CreateEditPage extends React.Component {

    constructor() {
        super();
        this.state = {
            form: {},
            organizations: [],
            checked: [],
            expanded: ["setup", "service"]
        };
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.getUserbyId(this.props.match.params.id);
        }
        this.getAppOrg()
    }

    getUserbyId = (id) => {
        UserApi
        .get(id)
        .then((res) => {
            this.setState({form: res, checked: res.permissions || [] })
        })
    }

    getAppOrg = () => {
        AppOrgApi
        .query()
        .then((res) => {
           this.setState({organizations: res.data})
        })
    }

    handleChange = (e) => {
        let {form} = this.state;
        form[e.target.name] = e.target.value;
        this.setState({ form });
    }

    handleSave = () => {
        if(this.props.match.params.id) {
            UserApi
                .update(this.state.form, this.props.match.params.id)
                .then((res) => {
                    if(res.status === 'success') {
                        this.props.history.push({pathname: '/users', state: {title: 'User'}})
                    }
                })
        } else {
            UserApi
                .create(this.state.form)
                .then((res) => {
                    if(res.status === 'success') {
                        this.props.history.push({pathname: '/users', state: {title: 'User'}})
                    }
                })
        }
    }

    resetPassword = () => {
        if(this.props.match.params.id) {
            UserApi
                .setpassword(this.state.form, this.props.match.params.id)
                .then((res) => {
                    if(res.status === 'success') {
                        this.props.history.push({pathname: '/users', state: {title: 'User'}})
                    }
                })
        }
    }

    selectFileNamecard = (e) => {

    }

    changeTree = (result, type) => {
        let {form} = this.state
        if(type === 'checked') {
            form.permissions = result
        }
        this.setState({[type]: result, form })
    }

    render() {
        let {form, checked, expanded} = this.state;
        let nodes = setNode({})
        return (
            <div className="row">
                <div className="col-sm-12">

                    <div className="row">
                        <div className="col-sm-6">
                        
                            <div className="card">
                                <div className="card-body">
            
                                    <FormUser
                                        form={form}
                                        handleChange={this.handleChange}
                                    />

                                    <CheckboxTree
                                        nodes={nodes}
                                        checked={checked}
                                        expanded={expanded}
                                        onCheck={checked => this.changeTree( checked, 'checked')}
                                        onExpand={expanded => this.changeTree( expanded, 'expanded')}
                                    />
            
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                        
                            <div className="card">
                                <div className="card-body">
                                    {
                                        !form.id ?
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <input type="radio" name="create_type" onChange={this.handleChange} value="new" checked={form.create_type==='new' ? true : false}/> {' Create new Org '} <br/>
                                                    <input type="radio" name="create_type" onChange={this.handleChange} value="exist" checked={form.create_type==='exist' ? true : false}/> {' Add to exist Org'}
                                                </div>
                                            </div>
                                        : null
                                    }
                                    {
                                        form.create_type === 'new' ?
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label htmlFor="name">Org Name</label>
                                                    <input type="text" className="form-control" name="org_name" value={form.org_name} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        : null
                                    }
                                    {
                                        form.create_type === 'exist' ?
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label>App Org ID</label>
                                                    <input type="text" className="form-control" name="app_organization_id" value={form.app_organization_id} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        : ''
                                    }
                                    {
                                        form.id ?
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label>App Org ID</label>
                                                    <input type="text" className="form-control" name="app_organization_id" value={form.app_organization_id} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        : ''
                                    }
                                </div>
                            </div>

                            
                            { form.id && 
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                <label htmlFor="name">Change password</label>
                                                <input type="password" className="form-control" name="password" value={form.password} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary" type="button" onClick={this.resetPassword}>Set password</button>
                                    </div>
                                </div>
                            }

                            { form.id && 
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                <label htmlFor="name">Activated</label>
                                                <SwitchButton
                                                    isChecked={form.activated}
                                                    onChange={(c) => {
                                                        this.handleChange({ target: { name: "activated", value: c } })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <Link className="btn btn-default" to={{pathname:`/users`, state: {title: 'User'}}}>
                                                Cancel
                                            </Link>
                                            {' '}
                                            <button className="btn btn-primary" type="button" onClick={this.handleSave}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default CreateEditPage;
